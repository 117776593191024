import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

const Result = () => {
  return (
    <div id="result" className="container">
      <Navbar />

      <Container className="tall-margin quiz text-center">
        <h1>Take the QR Code inside the UTGO</h1>
      </Container>

      <Container>
        <div
          id="qr-code"
          className="border border-secondary p-5 d-flex align-items-center justify-content-center"
        >
          <img src="https://placehold.co/300" width="250px" />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Result;
