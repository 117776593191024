import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Reward = () => {
  return (
    <div id="reward" className="vh-100">
      <Navbar absolute monitor />
      <div
        id="reward-text"
        className="h-100 d-flex align-items-center justify-content-center flex-column"
      >
        <img id="reward-img" src="https://placehold.co/100x150" />
        <div className="text-center">
          <h1>CONGRATS YOU GOT ZELDA UT</h1>
          <p>We have emailed you a voucher code</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reward;
