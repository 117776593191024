import React from "react";

const Footer = () => {
  return (
    <footer className="position-absolute bottom-0 start-0 w-100 mb-3">
      <div className="container">
        <img src="https://placehold.co/500x100" className="w-100" />
      </div>
    </footer>
  );
};

export default Footer;
