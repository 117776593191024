import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Idle from "./pages/Idle";
import Reward from "./pages/Reward";
import UserForm from "./pages/UserForm";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <div className="vh-100 d-flex align-items-center justify-content-center">
              <h1 className="fw-bold display-1">Invalid Route</h1>
            </div>
          }
        />
        <Route path="/monitor/*" element={<Idle />} />
        <Route path="/monitor/reward" element={<Reward />} />
        <Route path="/mobile/*" element={<UserForm />} />
        <Route path="/mobile/quiz" element={<Quiz />} />
        <Route path="/mobile/result" element={<Result />} />
      </Routes>
    </BrowserRouter>
  );
}
