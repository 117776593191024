import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Form } from "react-bootstrap";
import Footer from "../components/Footer";
import { ProgressBar, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Quiz = () => {
  const [progress, setProgress] = useState(30);

  return (
    <div id="quiz">
      <Navbar />
      <ProgressBar now={progress} className="rounded-0" />
      <Container className="tall-margin quiz text-center">
        <h1>What's your Go-To Music Genre?</h1>
      </Container>
      <QuestionGrid />
      <Footer />
    </div>
  );
};

const QuestionGrid = () => {
  const navigate = useNavigate();

  function linkToResult() {
    navigate("/mobile/result");
  }

  return (
    <Container fluid>
      <Row>
        <Col
          onClick={linkToResult}
          className="choice-item d-flex align-items-center justify-content-center border p-0"
        >
          Choice A
        </Col>
        <Col
          onClick={linkToResult}
          className="choice-item d-flex align-items-center justify-content-center border p-0"
        >
          Choice B
        </Col>
      </Row>
      <Row>
        <Col
          onClick={linkToResult}
          className="choice-item d-flex align-items-center justify-content-center border p-0"
        >
          Choice C
        </Col>
        <Col
          onClick={linkToResult}
          className="choice-item d-flex align-items-center justify-content-center border p-0"
        >
          Choice D
        </Col>
      </Row>
    </Container>
  );
};

export default Quiz;
