import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Form } from "react-bootstrap";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const UserForm = () => {
  return (
    <div id="user-form" className="container">
      <Navbar />
      <div className="tall-margin">
        <img src="https://placehold.co/500x300" className="w-100" />
      </div>
      <Form className="p-3">
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Control type="name" placeholder="NAME" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="email" placeholder="EMAIL" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPhone">
          <Form.Control type="phone" placeholder="PHONE" />
        </Form.Group>

        <Form.Group className="mb-3 d-flex" controlId="formBasicTC">
          <Form.Check type="checkbox" className="me-2" />
          <Form.Label>TERMS & CONDITIONS</Form.Label>
        </Form.Group>

        <div className="text-center">
          <Link
            to="/mobile/quiz"
            type="submit"
            className="btn btn-primary bg-red border-0 px-5 py-2"
          >
            START QUIZ
          </Link>
        </div>
      </Form>

      <Footer />
    </div>
  );
};

export default UserForm;
