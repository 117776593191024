import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const Idle = () => {
  const [inAnimation, setInAnimation] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const hasScanned = false;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;
    console.log(inputValue);
    triggerAnimation();
  };

  const triggerAnimation = () => {
    setInAnimation(true);
    setTimeout(() => {
      setInAnimation(false);
      navigate("/monitor/reward");
    }, 5000);
  };

  window.triggerAnimation = triggerAnimation;

  return (
    <div id="idle" className="vh-100">
      <Navbar absolute monitor />
      {inAnimation ? (
        <div
          id="animation-screen"
          className="h-100 d-flex align-items-center justify-content-center"
        >
          <img src="https://placehold.co/100x150" className="w-100" />
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center flex-column">
          <div id="idle-text">
            <h2>WHAT</h2>
            <h1>
              UT
              <br />
              RU<span className="small">?</span>
            </h1>
          </div>

          <div id="scan-prompt" className="border py-4 px-5 text-center">
            <h3 className="m-0">
              {hasScanned ? "SORRY, ALREADY SCANNED" : "SCAN QR CODE"}
            </h3>
          </div>
        </div>
      )}
      <Footer />

      <form onSubmit={submitForm}>
        <input ref={inputRef} onBlur={handleBlur} />
      </form>
    </div>
  );
};

export default Idle;
