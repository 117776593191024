import React from "react";
import { Row } from "react-bootstrap";

const Navbar = ({ absolute = false, monitor = false }) => {
  return (
    <nav
      id="navbar"
      className={`my-3 ${
        absolute ? "position-absolute top-0 start-0 w-100" : null
      }`}
    >
      <div className="d-flex align-items-center justify-content-center">
        <img
          width={monitor ? "200px" : "70px"}
          className="mx-2"
          src="https://placehold.co/500"
        />
        <img
          width={monitor ? "200px" : "70px"}
          className="mx-2"
          src="https://placehold.co/500"
        />
      </div>
    </nav>
  );
};

export default Navbar;
